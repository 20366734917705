<template>
  <div>
    <div class="page-header">
      <div class="page-header-content header-elements-md-inline">
        <div class="page-title">
          <h4>
            <span class="font-weight-semibold">Tambah Material Request</span>
          </h4>
        </div>
        
        <div class="header-elements d-none text-center text-md-left mb-3 mb-md-0">
          <a href="javascript:;" @click="$router.back()" class="btn btn-labeled btn-labeled-left bg-success-400">
            <b>
              <i class="icon-list"></i>
            </b> Daftar Material Request </a>
        </div>
      </div>
    </div>

      
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit" class="content pt-0">
      <div class="card mb-1">
        <div class="card-header">
          <div class="form-row">
            <div class="col-md-2">
              <div class="form-group">
                <label>Nama Approver <small class="txt_mandatory">*</small></label>
                <b-form-input v-model="row.almr_approver_name" class="form-control" placeholder="Nama Approver" />
                <VValidate name="Nama Approver" v-model="row.almr_approver_name"
                  :rules="toValidate(mrValidation.almr_approver_name)" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Ruangan <small class="txt_mandatory">*</small></label>
                <v-select placeholder="Pilih Ruangan" v-model="row.almr_unit_ruang"
                  :options="mLogistikRuangan" @input="pilihUnit($event)" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Nama Ruangan" 
                    v-model="row.almr_unit_ruang" 
                    :rules="toValidate(mrValidation.almr_unit_ruang)"
                  />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Unit <small class="txt_mandatory">*</small></label>
                <input type="text" v-model="row.mlu_name" disabled class="form-control" placeholder="Unit"
                  aria-label="Tanggal Lahir" aria-describedby="basic-addon2">
                  
                <VValidate 
                  name="Nama Unit" 
                  v-model="row.almr_unit" 
                  :rules="toValidate(mrValidation.almr_unit)"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="" for="">Waktu Verifikasi</label>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="input-group">
                      <datepicker input-class="form-control transparent" placeholder="Tanggal Verifikasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.almr_tanggal_verifikasi">
                      </datepicker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                      </div>
                    </div>
                    <VValidate name="Tanggal Verifikasi" v-model="row.almr_tanggal_verifikasi"
                      :rules="toValidate(mrValidation.almr_tanggal_verifikasi)" />
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.almr_waktu_verifikasi">
                      </vue-timepicker>
                    </div>
                    <VValidate name="Waktu Verifikasi" v-model="row.almr_waktu_verifikasi"
                      :rules="toValidate(mrValidation.almr_waktu_verifikasi)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header bg-white">
          <h6 class="card-title font-weight-semibold">Form Material Request</h6>
        </div>
        <div class="card-body">
          <div class="data_prev_cont">
            <div class="wrap_line" v-for="(v,k) in (dataKategori||[])" :key="k">
              <div class="cta_delete">
                <a href="javascript:;" v-if="(dataKategori||[]).length > 1" @click="deleteKategori(v,k)" class="btn btn-sm btn-icon bg-danger  rounded-circle" v-b-tooltip.hover title="Hapus">
                  <i class="icon-trash-alt"></i>
                </a>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Kategori <small class="txt_mandatory">*</small>
                  </label>
                  <v-select @input="changeKategori($event,k)" placeholder="Pilih Kategori" v-model="v.almrk_kategori"
                  :options="mLogistikKategori" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Kategori" 
                    v-model="v.almrk_kategori" 
                    :rules="toValidate(mrValidation.almrk_kategori)"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label>Gudang Tujuan <small class="txt_mandatory">*</small>
                  </label>
                  <v-select placeholder="Pilih Gudang Tujuan" v-model="v.almrk_gudang_tujuan"
                  :options="mLogistikGudang" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Gudang Tujuan" 
                    v-model="v.almrk_gudang_tujuan" 
                    :rules="toValidate(mrValidation.almrk_gudang_tujuan)"
                  />
                </div>
              </div>
              <div class="table-responsive" style="min-height: 490px;">
                <table class="table table-sm table-hover text-uppercase table-bordered table-input"
                style="width: 1300px;">
                  <thead>
                    <tr>
                      <th width="24" rowspan="2">#</th>
                      <th rowspan="2" width="300">Nama Barang</th>
                      <th rowspan="2" width="250">QTY</th>
                      <th rowspan="2">Harga Beli (Rp)</th>
                      <th class="text-center" colspan="5">Stock</th>
                      <th rowspan="2">Aksi</th>
                    </tr>
                    <tr>
                      <th>Tersedia</th>
                      <th>Buffer</th>
                      <th>On Order</th>
                      <th>Min</th>
                      <th>Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(v2,k2) in (v.item_data||[])" >
                    <tr :class="v2.almrd_is_pending == 'Y' ? 'table-warning': ''" :key="k2+'item_data'">
                      <td>{{k2+1}}</td>
                      <td>
                        <div class="d-flex flex-row bd-highlight mb-3">
                          <v-select style="flex:1;" placeholder="Pilih Item" class="med-selection select-paging"
                              @open="openKategori(v2.almrd_kategori_temp,v2.almrd_kategori)"
                              @search="filterData($event,k2,v2.almrd_kategori_temp)" v-model="v2.almrd_item_id" :options="getMasterByKat(v2.almrd_kategori_temp)"
                              label="text" @input="selectJumlahSatuan($event,v.almrk_gudang_tujuan,v2.almrd_kategori_temp,k2,k)" :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option">
                                  <span v-b-tooltip.hover.right placement='right' :title="v2.almrd_item_name">{{ v2.almrd_item_name }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                              </template>
                              <template #list-footer>
                                  <li class="list-paging">
                                      <div class="d-flex align-items-center justify-content-between">
                                          <b-button @click="changePage(currentPage,'min',v2.almrd_kategori_temp)" size="sm" variant="transparent"
                                              class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                          <small>Halaman {{currentPage}} dari 
                                            {{ceilData(v2.almrd_kategori_temp)}}</small>
                                          <b-button @click="changePage(currentPage,'plus',v2.almrd_kategori_temp)" size="sm" variant="transparent"
                                              class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                      </div>
                                  </li>
                              </template>
                          </v-select>
                          
                          <div class="pl-2 bd-highlight">
                            <a href="javascript:;"
                              class="btn btn-sm btn-icon border-info alpha-info text-info rounded-circle"
                              v-b-tooltip.hover title="Tambah Item" @click="openTambahItem(v.almrk_kategori_temp,k2,k)"
                             >
                              <i class="icon-plus2"></i>
                            </a>
                          </div>
                        </div>
                        
                        <VValidate :name="'Barang #'+(k+1)+(k2+1)" message="Barang Harus Diisi"
                          v-model="v2.almrd_item_id" 
                          :rules="toValidate(mrValidation.almrd_item_id)"
                        />
                      </td>
                      <td>
                        <div class="input-group d-flex justify-content-end">
                          <b-form-input :formatter="numberOnly" v-model="v2.almrd_qty" type="text" class="form-control ppAdjustInput" />
                          <b-form-input v-model="v2.almrd_satuan" disabled class="form-control ppAdjustInput" />
                        </div>
                        
                        <VValidate :name="'Satuan #'+(k+1)+(k2+1)" message="Satuan Harus Diisi"
                          v-model="v2.almrd_qty" 
                          :rules="toValidate(mrValidation.almrd_qty)"
                        />
                      </td>
                      <td>
                            
                        <div class="input-group">
                          <span class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                          </span>
                          <money v-bind="money" v-model="v2.almrd_harga" class="form-control" placeholder="e.g 1000.0000" />
                        </div>  
                          
                        <VValidate :name="'Harga Jual #'+(k+1)+(k2+1)" message="Harga Jual Harus Diisi"
                          v-model="v2.almrd_harga" 
                          :rules="toValidate(mrValidation.almrd_harga)"
                        />
                      </td>
                      <td>
                        <span class="font-weight-semibold">{{v2.tersedia||0}} <small>{{v2.almrd_satuan}}</small>
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-semibold">{{v2.buffer||0}} <small>{{v2.almrd_satuan}}</small>
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-semibold">{{v2.on_order||0}} <small>{{v2.almrd_satuan}}</small>
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-semibold">{{v2.min||0}} <small>{{v2.almrd_satuan}}</small>
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-semibold">{{v2.max||0}} <small>{{v2.almrd_satuan}}</small>
                        </span>
                      </td>
                      <td>
                        <a href="javascript:;"
                        @click="deleteData(v2,k2,k)"
                          class="btn btn-sm btn-icon border-danger alpha-danger text-danger rounded-circle"
                           v-b-tooltip.hover title="Hapus">
                          <i class="icon-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" class="text-center">
                        <a @click="addNewData(v,k)" href="javascript:;" class="btn btn-sm alpha-info border-info">
                          <i class="icon-plus2 mr-1"></i>Tambah Barang </a>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Upload Dokumen Referensi<small class="txt_mandatory">*</small></label>
                    <Uploader v-model="v.almrk_dokumen_refrensi" isDocument type="docimage" />
                    <VValidate 
                      name="Dokumen Referensi" 
                      v-model="v.almrk_dokumen_refrensi" 
                      :rules="toValidate(mrValidation.almrk_dokumen_refrensi)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="text-right">
              <a href="javascript:;" @click="addNewKategori" class="btn btn-info btn-sm btn-labeled btn-labeled-left">
                <b>
                  <i class="icon-plus2"></i>
                </b>
                <span>Tambah Kategori</span>
              </a>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-1">Kembali</button>
            <button type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
      </b-form>
    </validation-observer>


    <validation-observer ref="VFormAddNew">    
    <b-modal v-model="modalAddNewItem" @ok.prevent="doSubmitNewItem" :title="'Tambah Barang Baru'" size="lg" ok-title="Submit">
      <div class="row">
        <b-col lg="6">
          <b-form-group>
            <label>Nama<span class="text-danger mr5">*</span></label>
            <b-form-input :formatter="normalText" placeholder="Nama" type="text" class="form-control" v-model="rowAddItem.name"/>
            <VValidate 
              name="Nama" 
              v-model="rowAddItem.name" 
              :rules="{required:1, min:3, max: 128}"
            />
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group>
            <label>Bentuk/Sediaan<span class="text-danger mr5">*</span></label>
            <b-form-input :formatter="normalText" placeholder="Bentuk/Sediaan" type="text" class="form-control" v-model="rowAddItem.bentuk"/>
            <VValidate 
              name="Bentuk/Sediaan" 
              v-model="rowAddItem.bentuk" 
              :rules="{required:1, min:3, max: 128}"
            />
          </b-form-group>
        </b-col>
        
        <b-col lg="6" v-if="rowAddItem.kategori == 'OBAT'">
          <b-form-group>
            <label>Dosis/Unit</label>
            <div class="input-group">
              <b-form-input :formatter="number" placeholder="Dosis" type="text" class="form-control" v-model="rowAddItem.dosis"/>
              
              <div class="input-group-append">
              <b-form-input :formatter="normalText" placeholder="Unit" type="text" class="form-control" v-model="rowAddItem.satuan_dosis"/>
              </div>
            </div>
          </b-form-group>
        </b-col>
        
        <b-col lg="6" v-if="rowAddItem.kategori == 'OBAT' || rowAddItem.kategori == 'INFUS'">
          <b-form-group>
            <label>Metode / Rute Pemberian<span class="text-danger mr5">*</span></label>
            <b-form-input :formatter="normalText" placeholder="Metode / Rute Pemberian" type="text" class="form-control" v-model="rowAddItem.metode"/>
            <VValidate 
              name="Metode / Rute Pemberian" 
              v-model="rowAddItem.metode" 
              :rules="{required:1, min:3, max: 128}"
            />
          </b-form-group>
        </b-col>

        
        <b-col lg="6" v-if="rowAddItem.kategori == 'ASET' || rowAddItem.kategori == 'UMUM'">
          <b-form-group>
          <label>Kategori<span class="text-danger mr5">*</span></label>
          <v-select placeholder="Pilih Kategori" v-model="rowAddItem.kategori_barang"
              :options="mLogistikKategori" label="text" :clearable="true"
              :reduce="v=>v.value"></v-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" v-if="rowAddItem.kategori == 'ASET'">
          <b-form-group>
            <label>Merk<span class="text-danger mr5">*</span></label>
            <b-form-input :formatter="normalText" placeholder="Merk" type="text" class="form-control" v-model="rowAddItem.merk"/>
            <VValidate 
              name="Merk" 
              v-model="rowAddItem.merk" 
              :rules="{required:1, min:3, max: 128}"
            />
          </b-form-group>
        </b-col>


      </div>
    </b-modal>
    </validation-observer>

  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import VueTimepicker from 'vue2-timepicker'
import Datepicker from 'vuejs-datepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import {Money} from 'v-money'


import $ from 'jquery'
import _ from 'lodash'

export default {
  extends: GlobalVue,
  data() {
    return {
      mLogistikGudang: [],
      mLogistikRuangan: [],
      mLogistikKategori: [],
      dataKategori: [],

      mObat: [],
      mAlkes: [],
      mAlatInfus: [],
      mLogistikAset: [],
      mLogistikUmum: [],
      currentPage: 1,
      searchData: null,
      kategoriId: null,
      rowAddItem: {},
      modalAddNewItem: false
    }
  },
  computed: {
    validateDataItem(){
      let isValidate = true
      for (let i = 0; i < (this.dataKategori||[]).length; i++) {
        if(!(this.dataKategori[i]['item_data']||[]).length){
          isValidate = false
        }
      }
      return isValidate
    },
    selectedGudangIds(){
      let data = []
      for (let i = 0; i < (this.dataKategori||[]).length; i++) {
        data.push(this.dataKategori[i].almrk_gudang_tujuan)
      }
      return data
    },
    selectedKategoriIds(){
      let data = []
      for (let i = 0; i < (this.dataKategori||[]).length; i++) {
        data.push(this.dataKategori[i].almrk_kategori)
      }
      return data
    },
    selectedStatusIds(){
      let data = []
      for (let i = 0; i < (this.dataKategori||[]).length; i++) {
        let status = this.dataKategori[i].almrk_status || 'D' 
        data.push(status)
      }
      return data
    },

    selectedItemsBarangByItem(){
      let data = []
      return data
    },

    getTotalObat(){
      let total = 0 
      let perpage = 0
      for (let i = 0; i < (this.mObat||[]).length; i++) {
        total = this.mObat[i].totalRows
        perpage = this.mObat[i].per_page
      }
      return {
        total : total,
        perpage : perpage,
      }
    },
    getTotalAlkes(){
      let total = 0 
      let perpage = 0
      for (let i = 0; i < (this.mAlkes||[]).length; i++) {
        total = this.mAlkes[i].totalRows
        perpage = this.mAlkes[i].per_page
      }
      return {
        total : total,
        perpage : perpage,
      }
    },
    getTotalInfus(){
      let total = 0 
      let perpage = 0
      for (let i = 0; i < (this.mAlatInfus||[]).length; i++) {
        total = this.mAlatInfus[i].totalRows
        perpage = this.mAlatInfus[i].per_page
      }
      return {
        total : total,
        perpage : perpage,
      }
    },
    getTotalLogistikUmum(){
      let total = 0 
      let perpage = 0
      for (let i = 0; i < (this.mLogistikUmum||[]).length; i++) {
        total = this.mLogistikUmum[i].totalRows
        perpage = this.mLogistikUmum[i].per_page
      }
      return {
        total : total,
        perpage : perpage,
      }
    },
    getTotalLogistikAset(){
      let total = 0 
      let perpage = 0
      for (let i = 0; i < (this.mLogistikAset||[]).length; i++) {
        total = this.mLogistikAset[i].totalRows
        perpage = this.mLogistikAset[i].per_page
      }
      return {
        total : total,
        perpage : perpage,
      }
    },

    
  },
  components:{ 
    VueTimepicker,Datepicker,Money
  },
  methods: {
    doSubmitNewItem(){
      this.$refs['VFormAddNew'].validate().then(success=>{
        if(success){
          
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan mengganti kategori ini?',
              text: "Data barang akan di sesuaikan ulang dengan kategori yang dipilih",
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali',
              allowOutsideClick: false,
              allowEscapeKey: false,
          }).then(result => {  
            if(result.value){
              this.loadingOverlay = true
              let dataPost = this.rowAddItem 
              dataPost.type = 'submit-new-item'

              Gen.apiRest(
                "/do/"+'LogistikMaterialRequest',
                {data:dataPost}, 
                "POST"
              ).then(res=>{
                this.loadingOverlay = false   
                
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['almrd_item_id'] = res.data.value
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['almrd_item_name'] = res.data.text
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['almrd_satuan'] = res.data.satuan
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['almrd_is_pending'] = "Y"

                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['tersedia'] = null
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['on_order'] = null
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['min'] = null
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['max'] = null
                this.dataKategori[this.rowAddItem.index1]['item_data'][this.rowAddItem.index2]['buffer'] = null
              
                this.$swal({
                  icon: 'success',
                  title: 'Data Berhasil Ditambahkan',
                })

                this.modalAddNewItem = false
              })
            }
          })
        }
      })
    },
    openTambahItem(template,k2,k1){
      this.rowAddItem = {
        index1 : k1,
        index2 : k2,
        kategori : template,
        name : null,
        bentuk : null,
        metode : null,
        dosis : null,
        satuan_dosis : null,
        kategori_barang: null,
        merk: null,
      }
      this.modalAddNewItem = true
    },
    openKategori(kategori,kategori_id){
      this.currentPage = 1,
      this.searchData = null
      this.kategoriId = null
    
      if(kategori == 'ASET'){
        this.kategoriId = kategori_id
        let data = {
          type : 'select-paging',
          page : this.currentPage,
          search : this.searchData,
          kategori : kategori,
          kategori_id: this.kategoriId
        }
        Gen.apiRest(
          "/do/"+'LogistikMaterialRequest',
          {data:data}, 
          "POST"
        ).then(res=>{
          this.mLogistikAset = res.data.mBarang
        })
      }
    },
    getMasterByKat(kategori){
      let master
      if(kategori == 'OBAT'){
        master = this.mObat
      }else if(kategori == 'ALKES'){
        master = this.mAlkes
      }else if(kategori == 'INFUS'){
        master = this.mAlatInfus
      }else if(kategori == 'ASET'){
        master = this.mLogistikAset
      }else{
        master = this.mLogistikUmum
      }
      return master
    },
    selectJumlahSatuan(e,gudang,kategori,k2,k1){
      let master
      if(kategori == 'OBAT'){
        master = this.mObat
      }else if(kategori == 'ALKES'){
        master = this.mAlkes
      }else if(kategori == 'INFUS'){
        master = this.mAlatInfus
      }else if(kategori == 'ASET'){
        master = this.mLogistikAset
      }else{
        master = this.mLogistikUmum
      }

      let index = (master||[]).findIndex(x => x.value == e)

      if(index != -1){
        this.dataKategori[k1]['item_data'][k2]['almrd_item_id'] = master[index]['value']
        this.dataKategori[k1]['item_data'][k2]['almrd_item_name'] = master[index]['text']
        this.dataKategori[k1]['item_data'][k2]['almrd_satuan'] = master[index]['satuan']
        
        let data = {
          type: 'get-info-stok',
          gudang: gudang,
          kategori: kategori,
          barang_id: e
        }

        Gen.apiRest(
          "/do/"+'LogistikMaterialRequest',
          {data:data}, 
          "POST"
        ).then(res=>{
          this.dataKategori[k1]['item_data'][k2]['tersedia'] = res.data.tersedia
          this.dataKategori[k1]['item_data'][k2]['on_order'] = res.data.on_order
          this.dataKategori[k1]['item_data'][k2]['min'] = res.data.min
          this.dataKategori[k1]['item_data'][k2]['max'] = res.data.max
          this.dataKategori[k1]['item_data'][k2]['buffer'] = res.data.buffer
        })      
      //  get stok
      }else{
        this.dataKategori[k1]['item_data'][k2]['almrd_item_id'] = null
        this.dataKategori[k1]['item_data'][k2]['almrd_item_name'] = null
        this.dataKategori[k1]['item_data'][k2]['almrd_satuan'] = null
      
        this.dataKategori[k1]['item_data'][k2]['tersedia'] = null
        this.dataKategori[k1]['item_data'][k2]['on_order'] = null
        this.dataKategori[k1]['item_data'][k2]['min'] = null
        this.dataKategori[k1]['item_data'][k2]['max'] = null
        this.dataKategori[k1]['item_data'][k2]['buffer'] = null
      
      }



    },

    filterData: _.debounce(function (e,k2,kategori){
      if(e){
        this.searchData = e      
      }else{
        this.searchData = null
      }

      this.currentPage = 1
      
      let data = {
        type : 'select-paging',
        page : this.currentPage,
        search : this.searchData,
        kategori : kategori,
        kategori_id: this.kategoriId
      }

      Gen.apiRest(
        "/do/"+'LogistikMaterialRequest',
        {data:data}, 
        "POST"
      ).then(res=>{
        if(kategori == 'OBAT'){
          this.mObat = res.data.mBarang
        }else if(kategori == 'ALKES'){
          this.mAlkes = res.data.mBarang
        }else if(kategori == 'INFUS'){
          this.mAlatInfus = res.data.mBarang
        }else if(kategori == 'ASET'){
          this.mLogistikAset = res.data.mBarang
        }else{
          this.mLogistikUmum = res.data.mBarang
        }
      })
    },10),

    changePage(page,act,kategori){
      if(act == 'min'){
        if(page > 1){
          page -= 1
        }
      }else{
        if(page < this.ceilData(kategori)){
          page += 1
        }
      }

      this.currentPage = page
      
      let data = {
        type : 'select-paging',
        page : this.currentPage,
        search : this.searchData,
        kategori : kategori,
        kategori_id: this.kategoriId
      }

      Gen.apiRest(
        "/do/"+'LogistikMaterialRequest',
        {data:data}, 
        "POST"
      ).then(res=>{
        if(kategori == 'OBAT'){
          this.mObat = res.data.mBarang
        }else if(kategori == 'ALKES'){
          this.mAlkes = res.data.mBarang
        }else if(kategori == 'INFUS'){
          this.mAlatInfus = res.data.mBarang
        }else if(kategori == 'ASET'){
          this.mLogistikAset = res.data.mBarang
        }else{
          this.mLogistikUmum = res.data.mBarang
        }
      })
    },
    ceilData(kat){
      let ceilVal = null
      if(kat == 'OBAT'){
        ceilVal = this.getTotalObat
      }else if(kat == 'ALKES'){
        ceilVal = this.getTotalAlkes
      }else if(kat == 'INFUS'){
        ceilVal = this.getTotalInfus
      }else if(kat == 'ASET'){
        ceilVal = this.getTotalLogistikAset
      }else{
        ceilVal = this.getTotalLogistikUmum
      }  
      return Math.ceil(ceilVal.total/ceilVal.perpage)
    },
    changeKategori(e,k){
      if(!(this.dataKategori[k]['item_data']||[]).length){
        this.dataKategori[k]['item_data'] = []
        let index = (this.mLogistikKategori||[]).findIndex(x => x.value == e)
        if(index != -1){
          this.dataKategori[k]['almrk_kategori_temp'] = this.mLogistikKategori[index]['temp']
        }        
      }else{
        this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan mengganti kategori ini?',
            text: "Data barang akan di sesuaikan ulang dengan kategori yang dipilih",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then(result => {  
          if(result.value){
            this.loadingOverlay = true
            let dataPost = {
              id: this.dataKategori[k]['almrk_id'],
              type : "delete-data-by-kategori"
            }
            Gen.apiRest(
              "/do/" +  this.modulePage, {
                data: dataPost
              },
              "POST"
            ).then(res =>{
              this.dataKategori[k]['almrk_kategori'] = e
              this.dataKategori[k]['item_data'] = []
              let index = (this.mLogistikKategori||[]).findIndex(x => x.value == e)
              if(index != -1){
                this.dataKategori[k]['almrk_kategori_temp'] = this.mLogistikKategori[index]['temp']
              }
              this.loadingOverlay = false
            })
          }else{
            this.dataKategori[k]['almrk_kategori'] = this.dataKategori[k]['old_kategori']
          }
        })
      }
    },
    apiGetMaster(){
      let slugs = this.pageSlug
      Gen.apiRest(
        "/get/"+this.modulePage+'/'+
        slugs+`?master=1`, 
        {}
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
      })
    },
    pilihUnit(e){
      // default val
      this.row.almr_unit = null
      this.row.mlu_id = null
      this.row.mlu_name = null

      if(e){
        let index = (this.mLogistikRuangan||[]).findIndex(x => x.value == e)
        if(index !== -1){
          this.row.almr_unit = this.mLogistikRuangan[index]['unit']
          this.row.mlu_id = this.mLogistikRuangan[index]['unit']
          this.row.mlu_name = this.mLogistikRuangan[index]['nama_unit']
        }
      }
    },
    toValidate(val){
      return {...val}
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      data.almr_gudang_ids = this.selectedGudangIds
      data.almr_kategori_ids = this.selectedKategoriIds
      data.almr_status_ids = this.selectedStatusIds
      Gen.apiRest(
          "/do/"+this.modulePage,
          {data:data}, 
          "POST"
      )
    },1000),
    autoSaveKategori: _.debounce(function (data) {
      let dataPost = {
        type : 'auto-save-kategori',
        data : data
      }
      Gen.apiRest(
        "/do/" +  this.modulePage, {
          data: dataPost
        },
        "POST"
      )
    }, 1000), 

    addNewKategori(){
      this.loadingOverlay = true
      let dataPost = {
        type : 'add-kategori',
        almrk_almr_id: this.row.almr_id,
        almrk_kategori : null,
        almrk_kategori_temp: null,
        almrk_gudang_tujuan : null,
        almrk_dokumen_refrensi : null,
        almrk_status : null,
        almrk_is_draft : "Y",
      }
      Gen.apiRest(
        "/do/" +  this.modulePage, {
          data: dataPost
        },
        "POST"
      ).then(res =>{
        this.loadingOverlay = false
        this.dataKategori.push(res.data.row)
      })
    },
    deleteKategori(v,k){
      this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menghapus data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
      }).then(result => {  
        if(result.value){
          
          let dataPost = {
            type : 'delete-kategori',
            id : v.almrk_id,
          }

          Gen.apiRest(
            "/do/" +  this.modulePage, {
              data: dataPost
            },
            "POST"
          ).then(res =>{
            this.loadingOverlay = false
            this.dataKategori.splice(k,1)
          })
        }
     })
    },

    addNewData(v,kU){
      this.loadingOverlay = true
      let dataPost = {
        type : 'add-data',
        almrd_kategori: v.almrk_kategori,
        almrd_kategori_temp: v.almrk_kategori_temp,
        almrd_almr_id: this.row.almr_id,
        almrd_almrk_id : v.almrk_id,
        almrd_item_id : null,
        almrd_item_name : null,
        almrd_qty : null,
        almrd_satuan : null,
        almrd_harga : null,
      }
      Gen.apiRest(
        "/do/" +  this.modulePage, {
          data: dataPost
        },
        "POST"
      ).then(res =>{
        this.loadingOverlay = false
        this.dataKategori[kU].item_data.push(res.data.row)

      })
    },
    deleteData(v,k,kU){
      this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menghapus data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
      }).then(result => {  
        if(result.value){
          this.loadingOverlay = true

          let dataPost = {
            type : 'delete-data',
            id : v.almrd_id,
          }

          Gen.apiRest(
            "/do/" +  this.modulePage, {
              data: dataPost
            },
            "POST"
          ).then(res =>{
            this.loadingOverlay = false
            this.dataKategori[kU].item_data.splice(k,1)
          })
        }
     })
    },

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) return
        if (!this.validateDataItem) {
          return this.$swal({
            title: "Tidak Dapat Lanjut!",
            text: "Data Item Kategori Tidak Boleh Kosong",
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        }

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          let data = this.row
          data.type = "submit-data"
          data.dataKategori = this.dataKategori

          this.loadingOverlay = true
          
          this.loadingOverlay = false
        })
      })
    },

    // validateDataItem
  },
  mounted() {
    this.apiGet()
    this.apiGetMaster()
  },
  watch: {
    row: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    'dataKategori': {
      handler(v) {
        this.autoSaveKategori(v)
        this.autoSave(this.row)
      },
      deep: true
    }
    
  }
}

</script>
