<template>
  <div class="content-wrapper">
    <div class="pt-0 content" v-if="isList">
      <div class="card mt-3">
        <div class="card-header bg-white">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="form-row align-items-center">
                <div class="col-md-8">
                  <h5 class="card-title font-weight-semibold">{{ pageTitle }}</h5>
                </div>
                <div class="col-md-4 text-right">
                  <a href="javascript:;" @click="initMR" class="btn btn-labeled btn-labeled-left bg-blue-400">
                    <b><i class="icon-plus2"></i></b>
                    Tambah Material Request
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-4">
              <div class="card_summary">
                <div class="heading_card bg_1">
                  <p>Total Unit Requested</p>
                </div>
                <div class="card_fill">
                  <h3>3</h3>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card_summary">
                <div class="heading_card bg_2">
                  <p>Total Disetujui</p>
                </div>
                <div class="card_fill">
                  <h3>200</h3>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card_summary">
                <div class="heading_card bg_3">
                  <p>Total Ditolak</p>
                </div>
                <div class="card_fill">
                  <h3>100</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header bg-white">
          <div class="row align-items-center">
            <div class="col-md-auto">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <h5 class="card-title font-weight-semibold">Material Request Lists</h5>
                </div>
                <div class="col-md-auto">
                  <div class="form-group mb-0 d-flex">
                      <date-range-picker
                          ref="picker"
                          :locale-data="datePickerConfig.locale"
                          :autoApply="datePickerConfig.autoApply"
                          v-model="dateRange"
                          :opens="'right'"
                          :ranges="datePickerConfig.ranges"
                          @update="updateValues"
                      >
                          <template v-slot:input="picker">
                              {{ picker.startDate | date }} - {{ picker.endDate | date }}
                          </template>
                      </date-range-picker>
                      <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                      </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md">
              <div class="row gx-1">
                
                <div class="col-md-auto">
                    <div class="form-group mb-0">
                        <button @click="apiGet" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right.noninteractive ><i class="icon-spinner11"></i></button>
                    </div>
                </div> 
                <div class="col-md-4">
                  <v-select placeholder="-- Pilih Kategori -- " @input="doFill" v-model="filter.kategori"
                    :options="mLogistikKategori" label="text" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                </div>
                <div class="col-md-4">
                  <v-select placeholder="-- Pilih Gudang -- " @input="doFill" v-model="filter.gudang"
                    :options="mLogistikGudang" label="text" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                </div>
                <div class="col-md-3">
                  <v-select placeholder="-- Pilih Status -- " @input="doFill" v-model="filter.status"
                    :options="Config.mr.logistikStatus" label="text" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                  <input class="form-control"
                      v-model="filter.search"
                      @input="doSearch"
                      placeholder="Ketik No. MR"
                  />
                  <div class="form-control-feedback">
                      <i class="icon-search4 text-indigo"></i>
                  </div>
                      
                  <b-button
                      class="ml-1 d-inline-flex align-items-center"
                      variant="outline-success"
                      id="resetBtn"
                      @click="doResetData()"
                  >Reset</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered text-uppercase table-sm patient-table table-sm">
            <thead>
              <tr>
                <th rowspan="2">No</th>
                <th rowspan="2">No. MR</th>
                <th rowspan="2">Tanggal</th>
                <th rowspan="2">Nama/Unit</th>
                <th rowspan="2">Kategori/Gudang</th>
                <th rowspan="2">Total Item</th>
                <th colspan="2" class="text-center">Status</th>
                <th rowspan="2" class="text-center ">Status Pengajuan</th>
                <th rowspan="2" width="170" class="text-center">Aksi</th>
              </tr>
              <tr>
                <th>Disetujui</th>
                <th>Ditolak</th>
              </tr>
            </thead>
            <tbody v-for="(v,k) in (dataList||[])" :key="k">
              <tr>
                <td :rowspan="(v.kategoriData||[]).length">{{k+1}}</td>
                <td :rowspan="(v.kategoriData||[]).length"><span class="font-weight-semibold">{{v.almr_mr_no||"-"}}</span></td>
                <td :rowspan="(v.kategoriData||[]).length">{{v.almr_tanggal | moment("DD MMMM YYYY, HH:mm") }}</td>
                <td :rowspan="(v.kategoriData||[]).length"> <span class="text-success font-weight-semibold">{{v.almr_approver_name||"-"}}</span><br>{{v.mlu_name||"-"}}<br></td>
                
                <template v-if="(v.kategoriData||[]).length">
                <td>
                  {{getConfigDynamic(mLogistikKategori,v.kategoriData[0]['almrk_kategori'])||"-"}}
                  /
                  {{getConfigDynamic(mLogistikGudang,v.kategoriData[0]['almrk_gudang_tujuan'])||"-"}}
                </td>
                <td>{{v.kategoriData[0].totalItem||"-"}}</td>
                <td>{{v.kategoriData[0].almrk_total_item_approved||0}}</td>
                <td>{{v.kategoriData[0].almrk_total_item_rejected||0}}</td>
                <td>
                  <span v-if="v.kategoriData[0].almrk_is_draft == 'Y'" class="badge badge-danger mb-1">Draft</span>
                  <template v-else>
                    <span :class="`badge badge-${getConfigDynamic(Config.mr.logistikStatus,v.kategoriData[0].almrk_status,'color')} mb-1`">
                      {{getConfigDynamic(Config.mr.logistikStatus,v.kategoriData[0].almrk_status)||"-"}}
                      </span>
                  </template>

                    <span v-if="v.kategoriData[0].isHavePending" class="badge badge-warning mb-1">Pending</span>

                </td>
                </template>
                
                <template v-else>
                <td colspan="5" class="text-center">Tidak ada data</td>
                </template>

                <td :rowspan="(v.kategoriData||[]).length">
                  <router-link v-if="moduleRole('edit')" :to="{name: $route.name, params: {pageSlug: v.almr_id}}"
                  v-b-tooltip.hover.right title="Edit"
                    class="btn btn-icon rounded-round btn-sm alpha-success border-success">
                    <i class="icon-pencil"></i>
                  </router-link>

                  <a v-if="moduleRole('view_detail')" href="detail-material-request.php" v-b-tooltip.hover.right title="Lihat detail"
                    class="btn btn-icon rounded-round btn-sm alpha-info border-info">
                    <i class="icon-file-eye"></i>
                  </a>

                  <a v-if="moduleRole('view_logs')" href="#" data-toggle="modal" data-target="#LogsMr" v-b-tooltip.hover.right title="Logs"
                    class="btn btn-icon rounded-round btn-sm alpha-danger border-danger">
                    <i class="icon-sort-time-desc"></i>
                  </a>
                    
                  <a href="javascript:;" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger"
                    v-if="moduleRole('delete') && !v.almr_mr_no"
                    @click="doDelete(v)"
                    v-b-tooltip.hover.right title="Delete"><i
                      class="icon-bin"></i></a>
                </td>
              </tr>
              <template v-if="(v.kategoriData||[]).length >= 2">
                <template v-for="(v2,k2) in (v.kategoriData||[])" >
                  <tr v-if="k2>=1" :key="k2+'kategori'">
                    <td>{{getConfigDynamic(mLogistikKategori,v2['almrk_kategori'])||"-"}}
                      / {{getConfigDynamic(mLogistikGudang,v2['almrk_gudang_tujuan'])||"-"}}
                    </td>
                    <td>{{v2.totalItem||"-"}}</td>
                    <td>{{v2.almrk_total_item_approved||0}}</td>
                    <td>{{v2.almrk_total_item_rejected||0}}</td>
                    <td>
                      <span v-if="v2.almrk_is_draft == 'Y'" class="badge badge-danger mb-1">Draft</span>
                      <template v-else>
                        <span :class="`badge badge-${getConfigDynamic(Config.mr.logistikStatus,v2.almrk_status,'color')} mb-1`">{{getConfigDynamic(Config.mr.logistikStatus,v2.almrk_status)||"-"}}</span>
                      </template>

                      <span v-if="v2.isHavePending" class="badge badge-warning mb-1">Pending</span>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
            <template v-if="!(dataList||[]).length">
              <tbody>
                <tr>
                  <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
          <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
        </b-card-footer>
      </div>
    </div>
    <template v-else>
      <Form />
    </template>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Form from './Form.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import _ from 'lodash'
import $ from 'jquery'

export default {
  extends: GlobalVue,
  components:{Form,DateRangePicker},
  data() {
    return {
      dataResume: {},
      mLogistikKategori: [],
      mLogistikGudang: [],
      datePickerConfig: {
        startDate: new Date(),
        endDate: new Date(),
        autoApply: true,
        ranges: {
            'Hari Ini': [new Date(), new Date()],
            '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
            '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
            applyLabel: 'Terapkan',
            cancelLabel: 'Batal',
            direction: 'ltr',
            format: 'mm/dd/yyyy',
            separator: ' - ',
        }
      }, 
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
    }
  },
  methods: {
    initMR(){ 
      this.loadingOverlay = true      
      let data = {
        type : 'init-mr'
      }

      Gen.apiRest(
        "/do/" + 'LogistikMaterialRequest', {
          data: data 
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.$router.push({ name: this.menuPage, params: { pageSlug: resp.id}}).catch(()=>{})
      })
    },
    
    doConvertDate(){
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne(){
      this.filter.page = 1
    },
    doFill(){
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e){
      this.doConvertDate()
      this.doFill()
    },
    
    doResetData(){
      this.doReset()
      this.dateRange =  {
          startDate: new Date(),
          endDate: new Date(),
      }
      this.doConvertDate()
    },
    
    getConfigDynamic(master, value, field='text') {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index][field]
        }
      }
      return text
    },

    doDelete(v){
      this.$swal({
        title: "Apakah Anda yakin akan menghapus data ini?",
        text: "Anda tidak akan dapat memulihkan data ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Kembali',
      }).then(result=>{
        if(result.value){
          this.loadingOverlay = true
        
          let data = {
            type : 'delete-mr',
            id : v.almr_id
          }

          Gen.apiRest(
            "/do/" + 'LogistikMaterialRequest', {
              data: data 
            },
            "POST"
          ).then(res => {
            this.loadingOverlay = false
            this.$swal({
              title: "Data Berhasil Dihapus",
              icon: 'success',
            })
            this.apiGet()
          })

        }
      })
    },
    
    doSearch: _.debounce(function(){
      this.doFilter()
    },1000),
  },
  mounted() {
    this.filter.status = 'ALL'
    this.filter.kategori = 'ALL'
    if(this.isList){
      this.apiGet()
    }
  },
  filters: {
      date(val) {
          return val ? moment(val).format("D MMM YYYY") : ""
      }
  },
  watch:{
    $route(){
      if(this.isList){
        this.apiGet()
      }
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>